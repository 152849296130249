* {
	box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
.authWrapper {
	margin: 0 auto;
	display: block;
	max-width: 100vw;
}

body,
html,
.App,
#root,
.authOuter {
	max-height: 100vh;
	max-width: 100vw;
}

.authOuter {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: left;
	margin-top: 20px;
}

.authInner {
	max-width: 100vw;
	margin: auto;
	background: #ffffff;
	box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
	padding: 40px 55px 45px 55px;
	border-radius: 15px;
	transition: all 0.3s;
}

.authOuter .form-control:focus {
	border-color: #167bff;
	box-shadow: none;
}

.authOuter h1,
h3,
h2 {
	text-align: center;
	margin: 0;
	line-height: 1;
	padding-bottom: 20px;
}
