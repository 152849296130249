.Orders {
	margin-top: 20px;
	align-items: center;
	background-color: blue;
}

.FilterButtons {
	display: none;
}
.SelectOptions {
	display: block;
}

@media screen and (min-width: 576px) {
	.FilterButtons {
		display: block;
	}
	.SelectOptions {
		display: none;
	}
}
