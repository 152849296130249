.Landing {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../../assets/images/braaiguy.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100vw;
	height: 100vh;
	overflow: none;
}

.LandingVideo {
	height: 100vh;
	width: 100vw;
	opacity: 0.5;
}

@media screen and (max-width: 576px) {
	.LandingVideo {
		height: 100vh;
		max-width: 100vw;
		object-fit: cover;
	}
}
@media screen and (min-width: 576px) {
	.LandingVideo {
		height: 100vh;
		width: 100vw;
		object-fit: fill;
	}
}
