.Jumbotron {
	/* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(../../media/placeholder-image.jpg); */
	background-color: rgba(0, 0, 0, 0.6) !important;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.Jumbotron p,
h1 {
	color: white;
	text-align: center;
}
