/*! `Custom` Bootstrap 4 theme */
@import url(https://fonts.googleapis.com/css?family=Quicksand:200,300,400,700);
$headings-font-family: Quicksand;

$enable-grid-classes: true;
$primary: #911024;
$secondary: #fac751;
$success: #23863a;
$danger: #b7152e;
$info: #fdf9dd;
$warning: #ff9705;
$light: #134b20;
$dark: #343a40;
$white: #ffffff;

@import "bootstrap";

// Add SASS theme customizations here..
html {
	font-size: 1.2rem;
}

body {
	background-color: $secondary;
}

.crd-img-overlay {
	z-index: "200";
	position: "absolute";
	bottom: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	border-bottom-right-radius: 18px;
	border-bottom-left-radius: 18px;
}

.border-red {
	border: solid 2px rgba(183, 21, 46, 0.8) !important;
}

.product-card {
	min-height: 277px !important;
}

.qty-span {
	padding: 0 6px !important;
	border: 1px solid green;
}
.contact-card {
	border-radius: 18px !important;
}
.cart-btn {
	padding: 3px 3px !important;
	padding-bottom: 9px !important;
	font-size: 12px !important;
	vertical-align: middle;
}
.cart-btn-cart {
	padding: 0px 4px !important;
	padding-bottom: 9px !important;
	font-size: 12px !important;
	vertical-align: middle;
}

.checkout-container {
	max-width: 650px !important;
}

.sale-icon {
	color: $secondary;
	width: 70px;
	height: 70px;
	margin: 5px 0px -70px 5px;
	z-index: 100;
}
.card-title {
	font-size: 1rem;
	min-height: 30px;
}
.card-body {
	font-size: 1rem;
}

.card {
	width: 95%;
	min-width: 95%;
}

.card-img-top {
	min-height: 175px;
	height: 175px;
	object-fit: cover;
	border-radius: 18px 18px 18px 18px !important;
}
.modal-content {
	min-width: 25vw;
}
.card-btn {
	margin: 0 auto !important;
}

.btn-col {
	padding: 0px 0px !important;
}

.price-display {
	font-size: 1rem;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border: 1px solid rgba(250, 199, 81, 0.8) !important;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(250, 199, 81, 0.6) !important;
}

@include media-breakpoint-down(xs) {
	html {
		font-size: 0.9rem;
	}
	.card-title {
		font-size: 0.9rem;
	}
	.card-body {
		font-size: 0.9rem;
	}
	.card-btn {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}
	.card-img-top {
		max-width: 100%;
		min-height: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 20px;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}
	.price-display {
		font-size: 0.6rem;
	}
	.modal-content {
		min-width: 90vw;
	}
}

@include media-breakpoint-down(sm) {
	html {
		font-size: 0.75rem;
	}
	.card-title {
		font-size: 0.75rem;
		min-height: 30px;
	}
	.card-body {
		font-size: 0.75rem;
	}

	.modal-content {
		min-width: 90vw;
	}
}
@include media-breakpoint-up(md) {
	html {
		font-size: 0.9rem;
	}
	.card-btn {
		font-size: 0.8rem !important;
		width: 100% !important;
	}

	.card-title {
		min-height: 30px;
	}

	.modal-content {
		min-width: 50vw;
	}
}
@include media-breakpoint-up(lg) {
	html {
		font-size: 1rem;
	}
	.card-btn {
		font-size: 0.6rem !important;
		width: 100% !important;
	}

	.card-title {
		min-height: 33px;
	}
}
@include media-breakpoint-down(xl) {
	html {
		font-size: 1rem;
	}
	.card-btn {
		font-size: 0.6rem !important;
		width: 100% !important;
	}

	.card-title {
		min-height: 37px;
	}

	.modal-content {
		min-width: 30vw;
	}
}

$link-color: $info; //red
$link-hover-color: $secondary; //green
$link-hover-text-decoration: none;
$card-bg: #ffffff;
$card-border-radius: 16px;
$card-border-color: $primary;
$card-border-width: 2px;

//import styles at the bottom
@import "~bootstrap/scss/bootstrap.scss";
