.Logo {
	height: 100px;
	width: auto;
	display: inline-block;
	padding: 0 15px;
	box-sizing: border-box;
}

.Logo img {
	height: 100%;
	width: auto;
}

@media screen and (max-width: 672px) {
	.Logo img {
		height: auto;
		width: auto;
		max-width: 100%;
	}
}
