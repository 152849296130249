.NavBar {
	background-color: #911024 !important;
	color: #fbf09e !important;
	z-index: 3;
}

/* .NavLink {
	color: #fbf09e !important;
}

.NavLink :hover {
	color: purple !important;
} */

@media (max-width: 767px) {
	.NavContainer {
		margin: 3px auto;
		width: 100%;
		box-shadow: 3px 0px #9c2739;
	}
	.NavBarToggle {
		margin: 3px auto;
	}
}
