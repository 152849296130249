.MeatStore {
	width: 100%;
	background-color: #911024;
	color: #fbf09e;
}

.FilterButtons {
	display: none;
}
.FilterDropdown {
	display: block;
}

@media screen and (min-width: 576px) {
	.FilterButtons {
		display: block;
	}
	.FilterDropdown {
		display: none;
	}
}
