.totalPrice {
  text-align: center;
  color: white;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 17px;
}
.Pickup {
  text-align: center;
  color: white;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 17px;
  padding-bottom: 16px;
}

.submitOrderContainer {
  text-align: center;
  color: white;
  font-size: 1.5em;
  border-radius: 17px;
}

.address {
  font-size: 0.75rem;
}

.address > p {
  line-height: 0.5rem;
}
.address a {
  font-size: 1.2rem;
  text-decoration: underline;
  border: 1px solid #fac751;
  border-radius: 8px;
  background-color: #fac751;
  padding: 4px 6px;
  color: #343a40;
}

.address a:hover {
  font-size: 1.2rem;
  text-decoration: underline;
  border: 1px solid #fac751;
  border-radius: 8px;
  background-color: #343a40;
  padding: 4px 6px;
  color: #fac751;
}

.address > p:last-of-type {
  padding-bottom: 30px;
}

.Checkout {
  min-height: 57vh;
}
