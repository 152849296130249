.Footer {
	width: 100vw;
	height: auto;
}
.TelephoneContainer {
	border-radius: 50%;
	/* 	background-color: #fbf09e;
 */
	height: 50px;
	width: 50px;
	text-align: center;
	padding-top: 6px;
}
