.Card {
	border-radius: 20px !important;
}

.ImageContainer {
	width: 80%;
	position: relative;
	display: inline-block;
	margin: auto;
}

.ImageContainer img {
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

/* @media screen and (max-width: 672px) {
	.Card {
		font-size: 0.75rem;
	}
} */
