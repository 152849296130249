.Used {
	background-color: lightcoral;
}

.UnUsed {
	background-color: lightgreen;
}

.MainContent {
	min-height: 55vh;
}

.FilterList {
	list-style-type: none;
	color: white;
}

.FilterHeading {
	text-decoration: underline;
}
